<template>
  <div id="app">
    <div
      v-if="generalMsg.text != ''"
      class="top-banner"
      :style="
        `background-color:` +
        generalMsg.background_color +
        `; color:` +
        generalMsg.text_color +
        `;`
      "
    >
      <span class="texttop" v-html="generalMsg.text"></span>
    </div>
    <router-view />
  </div>
</template>
<script>
//import { isServer } from "@storefront/core/helpers";
import { Logger } from "@storefront/core/lib/logger";
import { isServer } from "@storefront/core/helpers";

import config from "@config";
const contentList = [
  "footer_menu_information",
  "footer_menu_service",
  "footer_menu_quick_links",
  "footer_contact",
  "order_today_tomorrow_at_home",
  "free_shipping",
  "our_advantages",
  "home_pictures",
];
const sliders = [
  "home",
  "home_gallery",
  "payment_providers",
  "delivery_partners",
];

export default {
  name: "Home",
  metaInfo() {
    return {
      htmlAttrs: { lang: this.$i18n.locale },
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "title",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_title
            : "",
        },
        {
          name: "keywords",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_keywords
            : "",
        },
        {
          name: "description",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_description
            : "",
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Home",
      // all titles will be injected into this template
      titleTemplate: "%s | " + config.app_name,
    };
  },
  computed: {
    cmsDefaultPageContent() {
      return this.$store.state.cmsPage.default;
    },
    generalMsg() {
      return this.$store.state.messages.generalMsg;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  watch: {
    "$store.state.messages.msgItems": async function () {
      Logger.debug("App", "watch", "IsCalled")();
      const msgs = await this.$store.dispatch("messages/giveLastMessages");
      Logger.debug("App", "watch", typeof msgs)();
      Logger.debug("App", "watch", msgs)();
      msgs.forEach(function (msg) {
        Logger.debug("app", "watch", msg)();
        this.makeToast(msg);
      }, this);
    },
    async language() {
      Logger.debug("App", "watch language", "IsCalled")();
      if (!isServer) {
        Logger.debug("App", "watch language", "IsCalled isServer")();
        this.$store.dispatch("messages/loadGeneralMsg");
        this.$store.dispatch("menu/loadMenu");

        this.$store.dispatch("cmsPage/loadDefault", {
          id: "home",
        });
        this.$store.dispatch("sliders/multiple", {
          key: "identifier",
          value: sliders,
        });

        this.$store.dispatch("cmsBlock/multiple", {
          key: "identifier",
          value: contentList,
        });
      }
      return true;
    },
  },
  components: {},
  async serverPrefetch() {
    await this.$store.dispatch("messages/loadGeneralMsg");
    await this.$store.dispatch("menu/loadMenu");
    await this.$store.dispatch("user/loadCountries");
    await this.$store.dispatch("product/productReviewRatingsMetadata");
    await this.$store.dispatch("partfinder/loadPartfinder");

    await this.$store.dispatch("stores/load");

    await this.$store.dispatch("cmsPage/loadDefault", {
      id: "home",
    });
    await this.$store.dispatch("sliders/multiple", {
      key: "identifier",
      value: sliders,
    });

    await this.$store.dispatch("cmsBlock/multiple", {
      key: "identifier",
      value: contentList,
    });
  },
  async mounted() {
    this.$store.dispatch("cart/loadCart");
    this.$store.dispatch("user/loadUser");
  },
  methods: {
    makeToast(msg) {
      console.log("makeToast");

      this.$bvToast.toast(msg.text, {
        title: msg.title,
        variant: msg.type,
        autoHideDelay: 5000,
        toaster: "b-toaster-top-full",
        solid: true,
        append: true,
      });
    },
  },
};
</script>

<style lang="scss">
#app {
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;

  .modal-open & {
    width: 100vw;
  }
}

.texttop {
  display: inline-block;
  position: relative;
  padding-left: 2px;
  padding-right: 2px;
  background: #fff;
  color: #000;
}

.top-banner {
  text-align: center;
  font-size: 23px;
  letter-spacing: 0.22rem;
  position: relative;
}

a {
  transition: 0.3s color ease-in-out;
}

.resize-active * {
  transition: none !important;
}
.toast-body {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}
.toast-header {
  strong {
    margin: 0 auto;
    font-size: 12px;
  }
}
</style>
